import React from 'react';
import Tetris from './components/Tetris';

const App = () => (
  <div className= 'App'>
    <Tetris />
  </div>
)

export default App;
